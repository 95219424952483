#privacy-policy-container {
    p {
        padding: 4px;
    }

    li {
        padding: 4px;
    }

    h3, h4 {
        margin-bottom: 30px;
    }

    .block {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    a {
        margin-left: 5px;
    }

    #image-container {
        img {
            max-width: 500px;
            width: 100%;
        }

        width: 100%;
        text-align: center;
    }

    padding: 30px;
}
